const telCodes = [
  {
    code: '58',
    country: 'Venezuela',
    name: '58 - Venezuela',
  },
  {
    code: '54',
    country: 'Argentina',
    name: '54 - Argentina',
  },
  {
    code: '56',
    country: 'Chile',
    name: '56 - Chile',
  },
  {
    code: '593',
    country: 'Ecuador',
    name: '593 - Ecuador',
  },
  {
    code: '598',
    country: 'Uruguay',
    name: '598 - Uruguay',
  },
  {
    code: '1',
    country: 'Estados Unidos',
    name: '1 - Estados Unidos',
  },
];
export {telCodes};
