import {getRole} from '@/utils/authdecode.js';
import {object, string, date, array as arraySchema,
  boolean, setLocale} from 'yup';

setLocale({
  mixed: {
    default: 'Campo inválido',
  },
  number: {
    max: ({max}) => ({
      key: `debe tener un valor máximo de ${max}`,
      values: {max},
    }),
    min: ({min}) => ({
      key: `debe tener un valor mínimo de ${min}`,
      values: {min},
    }),
  },
  string: {
    max: ({max}) => ({
      key: `debe tener como máximo ${max} caracteres`,
      values: {max},
    }),
    min: ({min}) => ({
      key: `debe tener como mínimo ${min} caracteres`,
      values: {min},
    }),
  },
});

/* Categoría: Formatos */
/**
 * @return {Date} fecha actual
 */
function todayDate() {
  return (new Date()).toISOString().split('T')[0];
}

/**
 * Esquemas de validación de entradas para formularios.
 * Ordenadas por categorías y alfabéticamente.
 * @returns {schema}
 */
/* Categoría: Entidades */
const personSchema = object().shape({
  identification: string().required('Ingresa tu identificación, es requerida'),
  identificationType: string()
      .required('Selecciona un tipo de identificación, es requerido'),
  gender: string().required('Selecciona un género, es requerido'),
  llname: string().max(20).trim().ensure(),
  lname: string().min(2).max(20).trim()
      .required('Ingresa el primer apellido, es requerido'),
  mname: string().max(20).trim().ensure(),
  fname: string().min(2).max(20).trim()
      .required('Ingresa el primer nombre es requerido'),
  born: date('Y-m-d').required('Ingresa la fecha de nacimiento, es requerido')
      .max(todayDate(), 'La fecha de nacimiento debe ser hoy, o días/meses/años'
        + ' anteriores.'),
});

const menorSchema = object().shape({
  gender: string().required('Selecciona un género, es requerido'),
  llname: string().max(20).trim().ensure(),
  lname: string().min(2).max(20).trim()
      .required('Ingresa el primer apellido, es requerido'),
  mname: string().max(20).trim().ensure(),
  fname: string().min(2).max(20).trim()
      .required('Ingresa el primer nombre es requerido'),
  born: date('Y-m-d').required('Ingresa la fecha de nacimiento, es requerido')
      .max(todayDate(), 'La fecha de nacimiento debe ser hoy, o días/meses/años'
        + ' anteriores.'),
});

const doctorSchema = object(personSchema).shape({
  matricula: string().required(function() {
    throw new Error('Indicar la matrícula.');
  }).min(2).max(20).trim(),
});

const appointmentSchema = object().shape({
  date: date('Y-m-d').required('Coloca la fecha de la cita')
      .min(todayDate(), 'La fecha de la cita debe ser para hoy '
        + 'ó días siguientes'),
});

const emailSchema = object().shape({
  email: string().required().email().trim(),
  primary: boolean(),
  status: string().trim(),
});

const diagnosisSchema = object().shape({
  conclusion: string().trim(),
  disease: object().shape({
    name: string().trim(),
  }),
});

const receipSchema = object().shape({
  medicine: object().shape({
    name: string().trim(),
    dose: string().trim(),
    presentation: string().trim(),
  }),
  custom: string().trim(),
  unit: string().trim(),
});

// /* Categoría: Formatos */
// /**
//  * @return {Date} fecha actual
//  */
// function todayDate() {
//   return (new Date()).toISOString().split('T')[0];
// }

/**
 * Calcular edad
 * @param {String} born Fecha en formato YYY-mm-dd
 * @return {Int} Edad
 */
function calculateAge(born) {
  // born = new Date(born.year, born.month, born.day);
  const ageDifMs = Date.now() - born.getTime();
  const ageDate = new Date(ageDifMs); // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
}
/* Categoría rangos */
/**
 *
 * @param {String} start Hora en formato HH:mm
 * @param {String} end Hora en formato HH:mm
 * @return {Boolean} True si es valido, false si no lo es
 */
function validHoursRange(start, end) {
  start = start.split(':');
  end = end.split(':');
  return !((start[0] > end[0] || (start[0] === end[0] && start[1] >= end[1])));
}
/**
 *
 * @param {String} hour Hora en formato HH:mm
 * @param {String} start Hora en formato HH:mm
 * @param {String} end Hora en formato HH:mm
 * @return {Boolean} True si es valido, false si no lo es
 */
function validHourInRange(hour, start, end) {
  hour = hour.split(':');
  start = start.split(':');
  end = end.split(':');
  const minHour = Number(hour[0] * 60 + hour[1]);
  const minStart = Number(start[0] * 60 + start[1]);
  const minEnd = Number(end[0] * 60 + end[1]);
  return !((minHour < minStart || minHour > minEnd));
}

/**
 * Debouncer para que una funcion se ejecute al cabo de b tiempo
 * @param {Function} a Funcion a ser llamada una vez se cumpla el timeout
 * @param {Int} b Tiempo a esperar por el timeout
 * @param {Boolean} c Indica si es inmediato o no
 * @return {Function} Funcion a ser ejecutada
 */
const debounce =
  (a, b = 250, c) => (...d) => {
    clearTimeout(c, c = setTimeout(() => a(...d), b));
  };

/**
 * Capitaliza la primera letra de una cadena string
 * @param {String} string Cadena de texto a capitalizar
 * @return {String}
 */
function capitalize(string) {
  if(string){
    const textotoLowerCase = string.toLowerCase()
    const textoCapitalize = textotoLowerCase.replace(/(^\w|\s\w)/g, m => m.toUpperCase())
    const textoSinEspacios = textoCapitalize.trim().replace(/\s+/g, " ")
    string = textoSinEspacios;
    return string
  }
}

/**
 * errorMessages Selecciona mensajes en base al error
 * @param {Error} error error recibido
 * @param {String} mensaje mensaje a mostrar por default
 * @param {String} tipo tipo de alerta a mostrar para el color
 * @return {Object}
 */
function errorMessages(error, mensaje, tipo) {
  if (error.networkError && (error.networkError.statusCode === 400
    || error.message === 'Network error: Failed to fetch')) {
    mensaje = 'Error de conexión. Verifica tu conexión e intenta de nuevo.';
    tipo = 'error';
  }
  if (error.graphQLErrors) {
    if (error.message.indexOf('You are not authorized') !== -1) {
      if (getRole() === 'DoctorSinValidar') {
        mensaje = 'Tu cuenta no ha sido validada. Te avisaremos por email '
        + 'cuando lo hagamos para que puedas usar esta opción. '
        + 'La seguridad de los pacientes es primordial para nosotros.';
      } else {
        mensaje = 'Tu plan no incluye acceso a esta opción.';
      }
    } else if (error.message.indexOf('Your token is expired') !== -1) {
      // if (getRole() == 'Doctor') {
      mensaje = 'Parece que olvidaste pagar el servicio de AMII. '
      + 'Ve al menú de pagos para seguir disfrutando de las ventajas que '
      + 'AMII tiene para ti.';
      // }
    }
    tipo = 'warning';
  }
  return {
    content: mensaje,
    type: tipo || 'warning',
  };
}
/** Devuelve una fecha en formato legible es_VE
 * @param {String} string Fecha en cadena de texto
 * @return {String}
 */
function fechaEsVe(string) {
  if (!string) return '';
  let fecha = new Date(string);
  const z = fecha.getTimezoneOffset();
  const v = fecha.valueOf();
  const r = z * 1000 * 60 + v;
  fecha = new Date(r);
  const opt = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  };
  if (string.indexOf('T') === -1 && string.indexOf(' ') === -1) {
    delete opt.hour;
    delete opt.minute;
  }
  return fecha.toLocaleDateString('es-VE', opt);
}
/** Devuelve una fecha en formato DD-MM-AAAA
 * @param {String} string Fecha en cadena de texto
 * @return {String}
 */
function fechaFormatoDMA(string) {
  if (!string) return '';
  const date = string.slice(0,10).split('-');
  return [date[2], date[1], date[0]].join('-');
}

/** Recibe una fecha en datetime y devuelve fecha en DD-MM-AAAA y hora en 12hrs
 * @param {String} datetime Fecha y hora en cadena de texto
 * @return {String}
 */
function formatoFechaHora(datetime) {
  if (!datetime) return ''

  const [DATE, TIME] = datetime.slice(0, -1).split('T');
  let [hour, minute] = TIME.split(':');
  const SUFFIX = hour < 12 ? 'am' : 'pm';
  hour = hour > 12 ? Number(hour) - 12 : hour;

  return DATE.split('-').reverse().join('-') + ` a las ${hour}:${minute} ${SUFFIX}`
}

/**
 * Devuelve un true si cumple con la validacion, caso contrario false
 * @param {String} identificationType Objeto con Expresion Regular a comparar
 * @param {String} identification Identificacion del Usuario
 * @return {Boolean}
 */
function validateIdentification(identificationType, identification) {
  const regExp = new RegExp(identificationType.regExp);
  return regExp.test(identification);
}

/**
 * Abre o cierra el item de ion-item-sliding
 * @param {Number} accessIndex índice del item a "deslizar"
 * @param {String} listName nombre de la lista ion-item-sliding a usar
 */
function toggleSlidingOptions(accessIndex , listName) {
  const Slidings = document.querySelectorAll(`[name="${listName}"]`);

  Slidings.forEach((item, index) => {
    const btnOpen = item.querySelector('[name="open"]');
    const btnClose = item.querySelector('[name="close"]');

    if(index === accessIndex) {
      btnOpen.classList.toggle('ion-hide')
      btnClose.classList.toggle('ion-hide')

      item.getSlidingRatio().then((res) => {
        if (res == 0) item.open()
        else item.close()
      })
    } else {
      item.getSlidingRatio().then((res) => {
        if (res == 0 && btnOpen.classList.contains('ion-hide')) {
          btnOpen.classList.toggle('ion-hide');
          btnClose.classList.toggle('ion-hide');
        }
      })
    }
  });
}

export {
  arraySchema,
  calculateAge,
  capitalize,
  fechaEsVe,
  fechaFormatoDMA,
  formatoFechaHora,
  debounce,
  doctorSchema,
  emailSchema,
  personSchema,
  menorSchema,
  appointmentSchema,
  todayDate,
  validHoursRange,
  validHourInRange,
  diagnosisSchema,
  receipSchema,
  errorMessages,
  validateIdentification,
  toggleSlidingOptions,
};
