import { createRouter, createWebHistory } from '@ionic/vue-router';
import { isPlatform } from '@ionic/vue';
import { RouteRecordRaw } from 'vue-router';
import { trackRouter } from 'vue-gtag-next';
import {isAuthenticated, isNotAuthenticated} from '@/vue-apollo';
import Resumen from '../views/Resumen.vue'
import Tratamientos from '../views/Tratamientos.vue';
import Tratamiento from '../views/Tratamiento.vue';
import Citas from '../views/Citas.vue';
import CitasMenor from '../views/CitasMenor.vue';
import Cita from '../views/Cita.vue';
import CitaNueva from '../views/CitaNueva.vue';
import Perfil from '../views/Perfil.vue';
import CitasTabs from '../components/CitasTabs.vue';
import MiGente from '../views/MiGente.vue'
import MisMedicos from '../views/MisMedicos.vue'
import Login from '../views/Login.vue';
import {
  ToRoute,
} from "@/types";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: Resumen,
    beforeEnter: isAuthenticated,
    meta: {title: 'AMII Paciente'},
  },
  {
    path: '/login/',
    name: 'Login',
    component: Login,
    beforeEnter: isNotAuthenticated,
    meta: {title: 'AMII Login'},
  },
  {
    path: '/login/validar/:code',
    name: 'Loginv',
    component: Login,
    beforeEnter: isNotAuthenticated,
    meta: {title: 'AMII Bienvenid@'},
  },
  {
    path: "/citas/",
    component: CitasTabs,
    beforeEnter: isAuthenticated,
    children: [
      {
        path: "",
        redirect: "/citas/propias",
      },
      {
        path: "propias",
        component: Citas,
        meta: {title: 'AMII Citas propias'},
      },
      {
        path: "menores",
        component: CitasMenor,
        meta: {title: 'AMII Citas de menores'},
      },
    ],
  },
  {
    path: '/citas/:id',
    name: 'cita',
    component: Cita,
    beforeEnter: isAuthenticated,
    meta: {title: 'AMII Cita'},
  },
  {
    path: '/citas/agendar',
    name: 'agendar',
    component: CitaNueva,
    beforeEnter: isAuthenticated,
    meta: {title: 'AMII Agendar Cita'},
  },
  {
    path: "/tratamientos/",
    component: Tratamientos,
    beforeEnter: isAuthenticated,
    children: [
      {
        path: "",
        redirect: "/tratamientos/propios",
      },
      {
        path: "propios",
        component: Tratamientos,
        meta: {title: 'AMII Tratamientos propios'},
      },
      {
        path: "terceros",
        component: Tratamientos,
        meta: {title: 'AMII Tratamientos de terceros'},
      },
    ],
  },
  {
    path: '/tratamientos/:id',
    name: 'tratamiento',
    component: Tratamiento,
    beforeEnter: isAuthenticated,
    meta: {title: 'AMII Tratamiento'},
  },
  {
    path: '/configuracion/perfil',
    name: 'perfil',
    component: Perfil,
    beforeEnter: isAuthenticated,
    meta: {title: 'AMII Perfil'},
  },
  {
    path: '/mi-gente',
    component: MiGente,
    beforeEnter: isAuthenticated,
    meta: {title: 'AMII Mi Gente'},
  },
  {
    path: '/mis-medicos',
    component: MisMedicos,
    beforeEnter: isAuthenticated,
    meta: {title: 'AMII Mis médicos'},
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.afterEach((to: ToRoute) => {
  if (isPlatform('desktop') || isPlatform('pwa')) {
    document.title = to.meta?.title || '';
  }
})

trackRouter(router);

export default router
