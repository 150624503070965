<template>
  <ion-card
    class="ion-text-start"
    type="button"
    button
    router-direction="forward"
    :router-link="MiGente ? {} : {name: 'tratamiento', params: {id: tratamiento.id}}"
  >
    <ion-card-header @click.stop="MiGente ? contenidoExpandido = !contenidoExpandido : ''">
      <ion-button
        v-if="MiGente"
        color="medium"
        class="ion-float-right mx-1"
        fill="outline"
        size="small"
        @click="contenidoExpandido = !contenidoExpandido"
      >
        Ver
        <font-awesome-icon
          v-if="contenidoExpandido"
          icon="minus"
          class="ml-1"
        />
        <font-awesome-icon
          v-else
          icon="plus"
          class="ml-1"
        />
      </ion-button>
      <div v-if="tratamiento.owner">
        <ion-grid>
          <ion-row>
            <ion-col>
              <span class="fw-bold">De: </span>
                {{tratamiento.owner.fname}} {{tratamiento.owner.mname}}
                {{tratamiento.owner.lname}} {{tratamiento.owner.llname}}
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>
      <ion-card-title :class="{'' : MiGente}">
        {{tratamiento.name}}
        <ion-chip
          v-if="!MiGente"
          :color="tratamiento.receipAlamrs && tratamiento.receipAlarms[0].status == 'Active' ? 'success' : 'warning'"
          class="ion-float-right px-2"
        >
          <font-awesome-icon
            v-if="tratamiento.receipAlamrs && tratamiento.receipAlarms[0].status == 'Active'"
            icon="check-circle"
            class="mr-1"
          />
          <font-awesome-icon
            v-else
            icon="minus-circle"
            class="mr-1"
          />
          <ion-label>
            {{estatusTratamientos[tratamiento.receipAlamrs && tratamiento.receipAlarms[0].status]}}
          </ion-label>
        </ion-chip>
      </ion-card-title>
      <ion-card-subtitle v-if="tratamiento.encounter">
        {{tratamiento.encounter.appointment.in.doctors[0].fname}}
        {{tratamiento.encounter.appointment.in.doctors[0].mname}}
        {{tratamiento.encounter.appointment.in.doctors[0].lname}}
        {{tratamiento.encounter.appointment.in.doctors[0].llname}}
      </ion-card-subtitle>
    </ion-card-header>
    <ion-card-content>
      <div v-if="(contenidoExpandido || !MiGente)">
        <ion-grid>
          <ion-row>
            <ion-col>
              <span class="fw-bold">Fecha</span>
              {{dateDMA(tratamiento.createdAt.formatted)}}
            </ion-col>
          </ion-row>
        </ion-grid>
        <!-- Lista de medicamentos recetados -->
        <h6 class="fw-bold d-inline-block">Medicamentos</h6>
        {{ listarMedicinas(tratamiento.receipAlarms) }}
        <ion-button v-if="!MiGente" color="primary" expand="block" fill="clear">
          Ver detalles
          <font-awesome-icon icon="angle-right" class="ml-1" />
        </ion-button>
      </div>
      <div v-else-if="MiGente">
        <ion-list>
          <ion-radio-group
            v-model="permission"
            :allow-empty-selection="true"
          >
            <ion-item>
              <ion-label>Permitir acceso</ion-label>
              <ion-radio slot="end" value="watch"></ion-radio>
            </ion-item>

            <ion-item>
              <ion-label>Permitir edición</ion-label>
              <ion-radio slot="end" value="manage"></ion-radio>
            </ion-item>
          </ion-radio-group>
        </ion-list>
      </div>
    </ion-card-content>
  </ion-card>
</template>

<script>
import { defineComponent } from "vue";
import {
    IonCard,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonCardContent,
    IonButton,
    IonGrid,
    IonRow,
    IonCol,
    IonList,
    IonItem,
    IonChip,
    IonLabel,
    IonRadioGroup,
    IonRadio,
    isPlatform
} from "@ionic/vue";

export default defineComponent({
  name: 'tarjetas-tratamientos-general',
  components: {
    IonCard,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonCardContent,
    IonButton,
    IonGrid,
    IonRow,
    IonCol,
    IonList,
    IonItem,
    IonChip,
    IonLabel,
    IonRadioGroup,
    IonRadio,
  },
  props: {
    tratamiento: {
      type: Object,
      default: function() {
        return {};
      },
    },
    MiGente: {
      type: Boolean,
      default: false,
    }
  },
  data(){
    return{
      expandibles: [],
      contenidoExpandido: false,
      estatusTratamientos: {
        Inactive: 'Inactivo',
        Active: 'Activo'
      },
      permission: '',
    }
  },
  emits: [
    // 'close',
    // 'saveSchedule',
    // 'saveTime',
    // 'saveAppointmenDate',
    // 'changeAppointment',
    'perm-selected',
  ],
  computed: {
    isIosPlatform() { // Retorna true si la plataforma es ios
      return isPlatform('ios');
    },
    // isIosPlatform() { // Retorna true si la plataforma es ios
    //   return isPlatform('ios');
    // },
  },
  watch: {
    permission(){
      this.$emit('perm-selected', {
        id: this.tratamiento.id,
        permission: this.permission,
      })
    }
  },
  mounted() {
    const array = []
    if(this.tratamiento.receips){
      this.tratamiento.receips.forEach(() => {
        array.push({expand: false});
        this.expandibles = array
      })
    }
  },
  methods: {
    dateDMA(fecha) {
      return (new Date(fecha)).toLocaleDateString() ;
    },
    listaAlternativas(alternativas) {
      const listaNombres = alternativas.map(alt => alt.medicine.name)
      return listaNombres.join(', ') + '.'
    },
    listarMedicinas(medicinas) {
      const medicines = medicinas.map(medicina => medicina.medicine)
      return medicines.join(', ') + '.'
    },
  },
})
</script>

<style lang="scss" scoped>
.ml-no-icon {
  margin-left: 19px;
}
</style>