<template>
  <ion-header>
    <ion-toolbar>
      <ion-title class="ion-padding-start user-select-none">Motivos de cancelación</ion-title>
      <ion-buttons slot="end">
        <ion-button
          color="dark"
          fill="clear"
          @click="$emit('close', false);"
        >
          <font-awesome-icon
            icon="times"
            size="lg"
            slot="icon-only"
          />
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content>
    <ion-grid>
      <ion-row class="ion-justify-content-center mt-2">
        <ion-col
          size="12"
          size-sm="11"
          class="px-3"
        >
          <ion-item>
            <ion-label position="stacked" class="user-select-none">Motivos</ion-label>
            <ion-textarea
              v-model.trim="reason"
              inputmode="text"
              :placeholder="`Describe los motivos por los cuales deseas cancelar ${esTratamiento ? 'el tratamiento.' : 'la cita.'}`"
              rows="5"
            ></ion-textarea>
          </ion-item>
        </ion-col>
        <ion-col
          size="12"
          class="mt-3 ion-text-center"
        >
          <ion-button
            color="success"
            :disabled="reason.length > 4 ? btnDisabled : true"
            @click="$emit('cancelar', reason)"
          >
            <font-awesome-icon icon="check" class="mr-1" />
            Enviar motivo
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-content>
</template>

<script>
import { defineComponent, ref } from "vue";
import {
  IonContent,
  IonTextarea,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonLabel,
  IonButton,
  IonButtons,
  IonHeader,
  IonTitle,
  IonToolbar,
} from "@ionic/vue";

export default defineComponent({
  name: 'modal-cancelar-cita-tratamiento',
  components: {
    IonContent,
    IonTextarea,
    IonGrid,
    IonRow,
    IonCol,
    IonItem,
    IonLabel,
    IonButton,
    IonButtons,
    IonHeader,
    IonTitle,
    IonToolbar,
  },
  props: {
    esTratamiento: {
      type: Boolean,
      required: false,
      default: function() {
        return false
      }
    },
    btnDisabled: {
      type: Boolean,
      default: function() {
        return false
      }
    }
  },
  emits: ['close', "alerta", "cancelar"],
  setup(){
    const reason = ref('')
    return{ reason }
  },
  methods: {
    cerrar() {
      this.reason = "";
      this.$emit('close');
    }
  },
})
</script>

<style>

</style>