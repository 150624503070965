import {createApp, provide, h} from 'vue';
import VueGtag from "vue-gtag-next";
import './registerServiceWorker';
import App from './App.vue'
import router from './router';

import { IonicVue } from '@ionic/vue';

// apollo
import {DefaultApolloClient} from '@vue/apollo-composable';
import {dapolloClient} from '@/vue-apollo';

// Font Awesome
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import './fa-icons'

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';

// ***Montar la app con apollo***
const app = createApp({
  setup() {
    provide(DefaultApolloClient, dapolloClient);
  },
  render() {
    return h(App);
  },
})
  .use(IonicVue)
  .component('font-awesome-icon', FontAwesomeIcon)
  .use(router)
  .use(VueGtag, {
    isEnabled: false,
    useDebugger: false,
    property: {
      id: 'G-L20M3S763Q',
    },
  });

  router.isReady().then(() => {
    app.mount('#app');
  });
