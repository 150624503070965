<template>
  <ion-item>
    <ion-label position="stacked" class="user-select-none">Género</ion-label>
    <ion-select
      ref="selectGender"
      interface="popover"
      placeholder="Seleccione"
      :value="value"
      @ionChange="changed($event.target.value)"
    >
      <ion-select-option
        v-for="genderOption in genderOptions"
        :key="genderOption.value"
        :value="genderOption.value"
      >
        {{ genderOption.name}}
      </ion-select-option>
    </ion-select>
  </ion-item>
</template>
<script>
import {IonItem, IonLabel, IonSelect, IonSelectOption} from '@ionic/vue';
export const SelectGender = {
  name: 'SelectGender',
  components: {
    IonItem,
    IonLabel,
    IonSelect,
    IonSelectOption,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      genderOption: {
        name: 'Seleccione',
        value: '',
      },
      genderOptions: [
        {
          name: 'Femenino',
          value: 'Femenino',
        },
        {
          name: 'Masculino',
          value: 'Masculino',
        },
        {
          name: 'Desconocido',
          value: 'Desconocido',
        },
        {
          name: 'Otro',
          value: 'Otro',
        },
      ],
      genderIcons: [
        {
          name: 'venus',
          value: 'Femenino',
        },
        {
          name: 'mars',
          value: 'Masculino',
        },
        {
          name: 'genderless',
          value: 'Desconocido',
        },
        {
          name: 'transgender',
          value: 'Otro',
        },
      ],
      genderIconColors: [
        {
          name: 'text-pink',
          value: 'Femenino',
        },
        {
          name: 'text-primary',
          value: 'Masculino',
        },
        {
          name: 'text-medium',
          value: 'Desconocido',
        },
        {
          name: 'text-purple',
          value: 'Otro',
        },
      ],
    };
  },
  mounted() {
    this.changed(this.value);
  },
  methods: {
    focusInput() {
      this.$refs.selectGender.focus();
    },
    changed(value) {
      this.$emit('input', value);
      this.genderOption = this.genderOptions.filter((i) =>
        i.value == value)[0];
    },
  },
};

const genderOptions = {};
SelectGender.data().genderOptions.forEach((e) => {
  genderOptions[e.value] = e.name;
});
const genderIcons = {};
SelectGender.data().genderIcons.forEach((e) => {
  genderIcons[e.value] = e.name;
});
const genderIconColors = {};
SelectGender.data().genderIconColors.forEach((e) => {
  genderIconColors[e.value] = e.name;
});
export {genderOptions, genderIcons, genderIconColors};
export default SelectGender;
</script>
<style lang="scss">
</style>
