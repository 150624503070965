<template>
  <ion-item>
    <ion-label position="stacked" class="user-select-none">Tipo de sangre (opcional)</ion-label>
    <ion-select
      ref="selectBloodType"
      interface="popover"
      placeholder="Seleccione"
      :value="value"
      @ionChange="changed($event.target.value)"
    >
      <ion-select-option
        v-for="bloodType in bloodTypes"
        :key="bloodType.value"
        :value="bloodType.value"
      >
        {{ bloodType.name}}
      </ion-select-option>
    </ion-select>
  </ion-item>
</template>
<script>
import {IonItem, IonLabel, IonSelect, IonSelectOption} from '@ionic/vue';
export const SelectBloodType = {
  name: 'SelectBloodType',
  components: {
    IonItem,
    IonLabel,
    IonSelect,
    IonSelectOption,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      bloodType: {
        name: 'Seleccione',
        value: '',
      },
      bloodTypes: [{
          name: 'Desconocido',
          value: '',
        },
        {
          name: 'A+',
          value: 'Ap',
        },
        {
          name: 'A-',
          value: 'Am',
        },
        {
          name: 'B+',
          value: 'Bp',
        },
        {
          name: 'B-',
          value: 'Bm',
        },
        {
          name: 'AB+',
          value: 'ABp',
        },
        {
          name: 'AB-',
          value: 'ABm',
        },
        {
          name: 'O+',
          value: 'Op',
        },
        {
          name: 'O-',
          value: 'Om',
        },
      ],
    };
  },
  mounted() {
    this.changed(this.value);
  },
  methods: {
    focusInput() {
      this.$refs.selectBloodType.focus();
    },
    changed(value) {
      this.$emit('input', value);
      this.bloodType = this.bloodTypes.filter((i) =>
        i.value == value)[0];
    },
  },
};

const bloodTypes = {};
SelectBloodType.data().bloodTypes.forEach((e) => {
  bloodTypes[e.value] = e.name;
});
export {bloodTypes};
export default SelectBloodType;
</script>
<style lang="scss">
</style>
