// Exporta las constantes usadas por los Tratamientos
const TratamientoUnidades = {
  gotas: 'Gota/s',
  pastilla: 'Tableta/s',
  capsulas: 'Cápsula/s',
  cucharadas: 'Cucharada/s',
  aplicaciones: 'Aplicación/es',
  inhalaciones: 'Inhalación/es',
  mg: 'Miligramo/s',
  ml: 'Mililitro/s',
  lavado: 'Lavado',
};

const TratamientoUnidadesTiempo = {
  dias: 'Días',
  dosis: 'Dosis',
  indefinido: 'Indefinido',
}

export {
  TratamientoUnidades,
  TratamientoUnidadesTiempo,
};