
import { LocalNotifications } from '@capacitor/local-notifications';

function setNotificationDefault(options) {
  const defaults = {
    id: new Date().getTime(),
    sound: null,
    attachments: null,
    actionTypeId: "",
  }

  switch (options.type) {
    case 'Receip_Alarm':
      if (options.person === 'me') {
        defaults.sound = ''
      } else {
        defaults.sound = null
      }
      break;

    default:
      break;
  }

  return defaults;
}

export function setNotifications(notifications, options = {}) {
  notifications.forEach((n, i) => {
    options.type = n.extra.type;
    Object.assign(n, setNotificationDefault(options))
    n.id = n.id + i*2;
  });

  return new Promise((resolve, reject) => {
    LocalNotifications.schedule({
      notifications,
    })
      .then(res => resolve(res))
      .catch(err => {
        switch (true) {
          case (err.indexOf("Error: Notifications") !=-1):
            return reject('Ha ocurrido un error, te sugerimos el uso de la versión android de AMII')
          default:
            alert(err+ notifications + ' error set')
            break
        }
        return reject(err)
      })
  })

}

export function cancelNotificationsByExtraId(id) {
  return new Promise((resolve, reject) => {
    LocalNotifications.getPending()
      .then(notifications => {
        if(notifications.notifications.length) {
          const notificationsFiltered = notifications.notifications.filter(n => {
            if (!n.extra) n.extra = {};
            return n.extra.id === id
          })
          if (notificationsFiltered.length) {
            LocalNotifications.cancel({notifications: notificationsFiltered})
              .then(() => resolve())
              .catch(err => {
                alert(err + ' ncancel')
                return reject(err)
              })
          } else {
            resolve()
          }
        } else {
          resolve()
        }
      })
      .catch(err => {
        alert(err + ' npending')
        return reject(err)
      })
    
  })
}

export function cleanAndSetNotifications(notifications, options = {}) {
  return new Promise((resolve, reject) => {
    cancelNotificationsByExtraId(notifications[0].extra.id)
      .then(()=>{
        setNotifications(notifications, options)
        .then(res => resolve(res))
        .catch(err => {
          alert(err + ' catch set clean')
          return reject(err)
        })
      })
      .catch(err => {
        alert(err + ' nclean')
        return reject(err)
      })
  })
}

export function isNotificationSetByExtraId(id) {
  return new Promise((resolve, reject) => {
    LocalNotifications.getPending()
      .then(notifications => {
        if(notifications.notifications.length) {
          const notificationsFiltered = notifications.notifications.filter(n => {
            if (!n.extra) n.extra = {};
            return n.extra.id === id
          })
          if (notificationsFiltered.length) {
            resolve(true)
          } else {
            resolve(false)
          }
        } else {
          resolve(false)
        }
      })
      .catch(err => {
        alert(err + ' se np')
        return reject(err)
      })
  })
}
