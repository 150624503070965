<template>
  <ion-content>
    <ion-header slot="fixed">
      <ion-toolbar class="ion-padding-start">
        <ion-title class="user-select-none">Cambiar la cita</ion-title>
        <ion-buttons slot="end">
          <ion-button
            color="dark"
            fill="clear"
            :disabled="paraCita.schedule ? btnDisabled : true"
            @click="$emit('close', false);"
          >
            <font-awesome-icon
              icon="times"
              size="lg"
              slot="icon-only"
            />
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding modal-content">
      <ion-grid>
        <ion-row class="ion-align-items-center ion-justify-content-center mt-3 px-3">
          <!-- Fecha de la cita -->
          <ion-col
            size="12"
            size-sm="10"
            size-md="8"
            class="py-2"
          >
            <vue-cal
              hide-view-selector
              :time="false"
              active-view="month"
              small
              locale="es"
              :min-date="minDate"
              :max-date="maxDate"
              :hide-weekdays="hiddenDays"
              :disable-views="['week','year','years','day']"
              @cell-focus="paraCita.schedule=''; setDate($event)"
            />
          <p class="mt-2 ion-text-center">
            Fecha seleccionada
            {{ paraCita.date }}
          </p>
          </ion-col>
        </ion-row>
        <ion-row class="ion-justify-content-center px-3">
          <ion-col
            size="12"
            size-sm="10"
            size-md="8"
            class="py-2"
          >
            <ion-item>
              <ion-label position="stacked">Horario del día</ion-label>
              <ion-select
                v-model="paraCita.schedule"
                interface="popover"
                :interface-options="{
                  cssClass: 'popover-large-options',
                }"
                placeholder="Seleccione"
                :disabled="!paraCita.date"
                @change="$emit('saveSchedule', paraCita.schedule);
                  paraCita.time = paraCita.schedule.start;
                  $emit('saveTime', paraCita.time)"
              >
                <ion-select-option
                  v-for="(sche, iSche) in schedules"
                  :key="iSche"
                  :value="sche"
                >
                  {{ sche.day.name }} de {{ sche.start }} a {{ sche.end }}
                </ion-select-option>
              </ion-select>
            </ion-item>
          </ion-col>
          <ion-col
            size="12"
            class="ion-text-center mt-3 mb-4"
          >
            <ion-button
              color="success"
              :disabled="paraCita.schedule ? btnDisabled : true"
              @click="$emit('changeAppointment')"
            >
              <font-awesome-icon icon="sync" class="mr-1" />
              Cambiar cita
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-content>
</template>

<script>
import { defineComponent } from "vue";
import VueCal from 'vue-cal';
import 'vue-cal/dist/vuecal.css';
import 'vue-cal/dist/i18n/es.js';
import {
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonSelect,
  IonSelectOption,
  IonLabel,
  IonButton,
  IonButtons,
  IonHeader,
  IonTitle,
  IonToolbar,
} from "@ionic/vue";

export default defineComponent({
  name: 'ModalAgendarCita',
  components: {
    IonContent,
    IonGrid,
    IonRow,
    IonCol,
    IonItem,
    IonSelect,
    IonSelectOption,
    IonLabel,
    IonButton,
    IonButtons,
    IonHeader,
    IonTitle,
    IonToolbar,
    VueCal,
  },
   props: {
    schedule: {
      type: Array,
      default: function() {
        return [];
      },
    },
    btnDisabled: {
      type: Boolean,
      default: function() {
        return false
      }
    }
  },
  data(){
    return{
      paraCita: {
        date: '',
        schedule: {},
        time: '',
      },
    }
  },
  emits: [
    'close',
    'saveSchedule',
    'saveTime',
    'saveAppointmenDate',
    'changeAppointment'
  ],
  computed: {
    hiddenDays() {
      const days = {
        Lunes: 1,
        Martes: 2,
        Miercoles: 3,
        Jueves: 4,
        Viernes: 5,
        Sabado: 6,
        Domingo: 7,
      };
      if (!this.schedule) return [];
      this.schedule.forEach((s)=> {
        delete days[s.day.name];
      });
      const hidden = [];
      for (const prop in days) {
        if (Object.prototype.hasOwnProperty.call(days, prop)) {
          hidden.push(days[prop]);
        }
      }
      return hidden;
    },
    minDate() {
      const now = new Date();
      return now;
    },
    maxDate() {
      const now = new Date();
      const date = new Date(now);
      date.setDate(now.getDate() + 90);
      return date;
    },
    schedules() {
      if (!this.schedule) return [];
      const d = new Date(this.paraCita.date);
      const z = d.getTimezoneOffset();
      const v = d.valueOf();
      const r = z * 1000*60 + v;
      const da = new Date(r);
      const k = da.getDay();
      const days = [
        'Domingo',
        'Lunes',
        'Martes',
        'Miercoles',
        'Jueves',
        'Viernes',
        'Sabado',
      ];
      const schedule = this.schedule.filter((l) => l.day.name == days[k]);
      return schedule;
    },
  },
  watch: {
    schedules(newS) {
      if (newS.length == 1) this.autoSelectSchedule(newS[0]);
    },
  },
  methods: {
    cerrar() {
      this.reason = "";
      this.$emit('close');
    },
    autoSelectSchedule(schedule) {
      this.paraCita.schedule = schedule;
      this.paraCita.time = schedule.start;
      this.$emit('saveSchedule', this.paraCita.schedule);
      this.$emit('saveTime', this.paraCita.time);
    },
    setDate(date) {
      const d = new Date(date);
      let month = '' + (d.getMonth() + 1);
      let day = '' + d.getDate();
      const year = d.getFullYear();

      if (month.length < 2) {
        month = '0' + month;
      }
      if (day.length < 2) {
        day = '0' + day;
      }
      this.paraCita.date = [year, month, day].join('-');
      this.$emit('saveAppointmenDate', this.paraCita.date);
    },
  },
})
</script>

<style lang="scss" scoped>
  .modal-content::part(scroll) {
    margin-top: 3.5rem;
  }

</style>