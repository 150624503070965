function formatearHorario (horario) {
  if (horario) {
    const semana = [
      {
        nombre: 'Lunes',
        horarios: []
      },
      {
        nombre: 'Martes',
        horarios: []
      },
      {
        nombre: 'Miercoles',
        horarios: []
      },
      {
        nombre: 'Jueves',
        horarios: []
      },
      {
        nombre: 'Viernes',
        horarios: []
      },
      {
        nombre: 'Sabado',
        horarios: []
      },
      {
        nombre: 'Domingo',
        horarios: []
      }
    ]
    semana.forEach((s) =>{
      const dias = horario.filter((l) => l.day.name == s.nombre)
      if (dias.length) {
        s.horarios = dias;
      }
    })
    const horarioFormateado = semana.filter((s) => s.horarios.length)
    return horarioFormateado
  }
}

// Recibe hora en formato 24hr hh:mm
function hourFormatter(time) {
  if (!time) return '';

  const [hourS, minuteS] = time.split(':');
  let hour = Number(hourS);
  const suffix = hour < 12 ? 'am' : 'pm';
  hour = hour > 12 ? hour - 12 : hour;

  time = hour < 10 ? `0${hour}` : String(hour);
  time += `:${minuteS} ${suffix}`;

  return time;

}

export {formatearHorario, hourFormatter}