import { ApolloLink } from 'apollo-link';
import { Preferences } from '@capacitor/preferences';
import { setContext } from 'apollo-link-context'
import { ApolloClient } from '@apollo/client/core';
import { createHttpLink } from "apollo-link-http";
import { cache } from "@/state/initCache.js";
import {typeDefs, resolvers} from '@/state/schema.js';

// import {errorMessages} from '@/utils/validations.js';

// Name of the localStorage item
const AUTH_TOKEN = 'apollo-token'; // Capacitor agrega un _cap_, usado en tokenName

// Authorization
let token;
const authLink = setContext(async (_, { headers }) => {
  if (token) return {
    headers: {
      ...headers,
      Authorization: 'Bearer '+token || ''
    }
  };
  // Use your async token function here:
  token = (await Preferences.get({key:AUTH_TOKEN})).value;
  // Return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      Authorization: 'Bearer '+token || ''
    }
  }
})
// Http endpoint
const httpEndpoint = process.env.NODE_ENV === 'production'
  ? process.env.VUE_APP_GRAPHQL_HTTP || 'http://localhost:4000/graphql'
  : 'http://localhost:4000/graphql';

// HTTP link
const httpLink = createHttpLink({
  uri: httpEndpoint,
});

// Config
const defaultOptions = {
  // You can use `https` for secure connection (recommended in production)
  uri: httpEndpoint,
  // You can use `wss` for secure connection (recommended in production)
  // Use `null` to disable subscriptions
  // wsEndpoint: process.env.VUE_APP_GRAPHQL_WS || 'ws://localhost:4000/graphql',
  // LocalStorage token
  // tokenName: AUTH_TOKEN,
  // Enable Automatic Query persisting with Apollo Engine
  persisting: false,
  // Use websockets for everything (no HTTP)
  // You need to pass a `wsEndpoint` for this to work
  websocketsOnly: false,
  // Is being rendered on the server?
  ssr: false,

  // Override default apollo link
  // note: don't override httpLink here, specify httpLink options in the
  // httpLinkOptions property of defaultOptions.
  // link: myLink
  link: ApolloLink.from([
    authLink,
    httpLink,
  ]),

  // Override default cache
  cache,

  // Override the way the Authorization header is set
  // getAuth: (tokenName) => ...

  // Additional ApolloClient options
  // apollo: { ... }

  // Client local data (see apollo-link-state)
  // clientState: { resolvers: { ... }, defaults: { ... } }
  typeDefs,
  resolvers,
  fetchPolicy: 'cache-and-network',
};

// const {apolloClient, wsClient} = ApolloClient({
export const dapolloClient = new ApolloClient({
  // uri: httpEndpoint,
  // cache: cache,
  ...defaultOptions,
  // ...options,
});
// Manually call this when user log in
/**
 * onLogin al hacer login
 * @param {apolloClient} apolloClient apollo Client
 * @param {String} token token de sesión
 */
export async function onLogin(apolloClient, token) {
  // if (typeof localStorage !== 'undefined' && token) {
  //   localStorage.setItem(AUTH_TOKEN, token);
  // }
  await Preferences.set({key: AUTH_TOKEN, value: token});
  // if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient);
  try {
    // await apolloClient.resetStore();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('%cError on cache reset (login)', 'color: orange;', e.message);
  }
}

// Manually call this when user log out

/**
 * onLogin al hacer login
 * @param {apolloClient} apolloClient apollo Client
 * @param {String} token token de sesión
 */
export async function onLogout(apolloClient) {
  token = null;
  await Preferences.remove({key: AUTH_TOKEN});
  // if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient);
  try {
    apolloClient.resetStore();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('%cError on cache reset (logout)', 'color: orange;', e.message);
  }
}

/**
 * isAuthenticated valida si esta autenticado el usuario
 * @param {Object} to
 * @param {Object} from
 * @param {Function} next
 * @return {Boolean}
 */
export async function isAuthenticated(to, from, next) {
  return (await Preferences.get({key:AUTH_TOKEN})).value ? next() : next('/login');
}

/**
 * isAuthenticated valida si esta autenticado el usuario
 * @param {Onject} to
 * @param {Onject} from
 * @param {Function} next
 * @return {Boolean}
 */
export async function isNotAuthenticated(to, from, next) {
  return (await Preferences.get({key:AUTH_TOKEN})).value ? next('/') : next();
}
