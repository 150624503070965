// Exporta las constantes usadas por los receipAlarms
const RecipeAlarmStatus = {
  Active: 'Activo',
  Inactive: 'Inactivo',
  Stopped: 'Detenido',
  Finished: 'Terminado'
};

const RecipeAlarmStatusColors = {
  Active: 'success',
  Inactive: 'warning',
  Stopped: 'danger',
  Finished: 'light'
};

export {
  RecipeAlarmStatus,
  RecipeAlarmStatusColors,
};