import { register } from 'register-service-worker'

register('/service-worker.js', {
  registrationOptions: { scope: './' },
  ready () {
    console.log('Service worker activo.')
  },
  registered () {
    console.log('Service worker registrado.')
    // Buscado por cambios cada 8 horas
    setInterval(() => {
      register.update();
    }, 1000 * 60 * 60 * 8);
  },
  cached () {
    console.log('Contenido en cache para uso offline.')
  },
  updatefound () {
    console.log('Nuevo contenido descargando.')
  },
  updated () {
    console.log('Nueva versión disponible; por favor actualiza.')
    document.dispatchEvent(
        new CustomEvent('actualizacionServiceWorker', {detail: register}),
    );
  },
  offline () {
    console.log('Sin conexión a internet. App en modo offline.')
  },
  error (error) {
    console.error('Error registrando el service worker:', error)
  }
})
