import {library} from '@fortawesome/fontawesome-svg-core';
import {
  faBars, faHome, faClipboardList, faPills, faCog, faInbox, faClipboardCheck,
  faAngleRight, faUserMd, faPen, faSync, faTimes, faCircleNotch, faCheckCircle,
  faMars, faVenus, faGenderless, faTransgender, faEye, faEyeSlash, faCheck,
  faLock, faTrash, faPlus, faPlusCircle, faTint, faCalendarTimes, faCalendarDay,
  faChild, faClock, faMapMarkedAlt, faSearch, faArrowLeft, faArrowDown, faChevronLeft, faChevronDown,
  faMapMarkerAlt, faPhoneAlt, faCalendarAlt, faStopwatch, faHospital, faUser, faArrowUp, faChevronUp,
  faExclamationTriangle, faMinusCircle, faArrowRight, faChevronRight, faMinus,
  faEnvelopeOpen, faKey, faEnvelope, faIdCard, faSignInAlt, faSignOutAlt, faBell, faBellSlash,
  faExclamationCircle, faMousePointer, faNotesMedical, faClipboard, faHourglassHalf, faBan, faInfo, faUsers, faShareAlt, faAngleLeft, faUndoAlt,
  faStethoscope, faStar
} from '@fortawesome/free-solid-svg-icons';
// import {faThumbsUp} from '@fortawesome/free-regular-svg-icons';
// import {
//   faFacebookSquare, faInstagram, faTwitterSquare,
// } from '@fortawesome/free-brands-svg-icons';
library.add(
  // Solid
  faBars, faHome, faClipboardList, faPills, faCog, faInbox, faClipboardCheck,
  faAngleRight, faUserMd, faPen, faSync, faTimes, faCircleNotch, faCheckCircle,
  faMars, faVenus, faGenderless, faTransgender, faEye, faEyeSlash, faCheck,
  faLock, faTrash, faPlus, faPlusCircle, faTint, faCalendarTimes, faCalendarDay,
  faChild, faClock, faMapMarkedAlt, faSearch, faArrowLeft, faArrowDown, faChevronLeft, faChevronDown,
  faMapMarkerAlt, faPhoneAlt, faCalendarAlt, faStopwatch, faHospital, faUser, faArrowUp, faChevronUp,
  faExclamationTriangle, faMinusCircle, faArrowRight, faChevronRight, faMinus,
  faEnvelopeOpen, faKey, faEnvelope, faIdCard, faSignInAlt, faSignOutAlt, faBell, faBellSlash,
  faExclamationCircle, faMousePointer, faNotesMedical, faClipboard, faHourglassHalf, faBan, faInfo, faUsers, faShareAlt, faAngleLeft, faCircleNotch, faUndoAlt,
  faStethoscope, faStar
  // Brands
);