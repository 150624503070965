
import gql from 'graphql-tag';
import { InMemoryCache } from '@apollo/client/core';

const cache = new InMemoryCache();

cache.writeQuery({
  query: gql`
    query estatusNotificaciones {
      estatusNotificaciones {
        permiso
        esCompatible
        existeSuscripcion
      }
    }`,
  data: {
    estatusNotificaciones: {
      __typename: 'EstatusNotification',
      permiso: 'default',
      esCompatible: !('serviceWorker' in navigator)
      ? false : !('PushManager' in window)
      ? false : true,
      existeSuscripcion: false,
    },
  }
});

export {cache};
