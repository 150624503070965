<template>
  <ion-item>
    <ion-label position="stacked" class="user-select-none">Parentesco</ion-label>
    <ion-select
      ref="selectKinship"
      interface="popover"
      placeholder="Seleccione"
      :value="value"
      @ionChange="changed($event.target.value)"
    >
      <ion-select-option
        v-for="kinshipTypeOption in kinshipTypes"
        :key="kinshipTypeOption.value"
        :value="kinshipTypeOption.value"
      >
        {{ kinshipTypeOption.name}}
      </ion-select-option>
    </ion-select>
  </ion-item>
</template>
<script>
import {IonItem, IonLabel, IonSelect, IonSelectOption} from '@ionic/vue';
export const SelectKinship = {
  name: 'SelectKinship',
  components: {
    IonItem,
    IonLabel,
    IonSelect,
    IonSelectOption,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      kinshipType: {
        name: 'Seleccione',
        value: '',
      },
      kinshipTypes: [
        {
          name: 'Madre',
          value: 'Mother',
        },
        {
          name: 'Padre',
          value: 'Father',
        },
        {
          name: 'Hija',
          value: 'Daughter',
        },
        {
          name: 'Hijo',
          value: 'Son',
        },
        {
          name: 'Hermana',
          value: 'Sister',
        },
        {
          name: 'Hermano',
          value: 'Brother',
        },
        {
          name: 'Abuelo',
          value: 'Grandfather',
        },
        {
          name: 'Abuela',
          value: 'Grandmother',
        },
        {
          name: 'Tío',
          value: 'Uncle',
        },
        {
          name: 'Tía',
          value: 'Aunt',
        },
        {
          name: 'Otro',
          value: 'Other',
        },
      ],
    };
  },
  mounted() {
    this.changed(this.value);
  },
  methods: {
    focusInput() {
      this.$refs.SelectKinship.focus();
    },
    changed(value) {
      this.$emit('input', value);
      this.kinshipType = this.kinshipTypes.filter((i) =>
        i.value == value)[0];
    },
  },
};

const kinshipTypes = {};
SelectKinship.data().kinshipTypes.forEach((e) => {
  kinshipTypes[e.value] = e.name;
});
export {kinshipTypes};
export default SelectKinship;
</script>
<style lang="scss">
</style>
