
import { Preferences } from '@capacitor/preferences';

/**
 * getAuthPayload devuelve el payload del token en json
 * @return {Object} payload en JSON
 */
async function getAuthPayload() {
  const token = (await Preferences.get({key:'apollo-token'})).value
  // const token = localStorage.getItem('apollo-token');
  const payload = token ? token.split('.')[1] : null;
  return payload ? JSON.parse(atob(payload)) : {};
}

/**
 * getAuthPayload devuelve el payload del token en json
 * @return {String} el identificador de la persona loggueada
 */
async function getPersonId() {
  return (await getAuthPayload()).id;
}

/**
 * getAuthPayload devuelve el payload del token en json
 * @return {String} el nombre completo de la persona loggueada
 */
function getPersonName() {
  return getAuthPayload().name;
}

/**
 * getAuthPayload devuelve el payload del token en json
 * @return {String} el rol de la persona loggueada
 */
function getRole() {
  return getAuthPayload().role;
}

/**
 * getAuthPayload devuelve el payload del token en json
 * @return {String} el fecha de solvencia en segundos de la persona loggueada
 */
function getSolvency() {
  return getAuthPayload().solvent;
}

export {
  getPersonId,
  getPersonName,
  getRole,
  getSolvency,
};
