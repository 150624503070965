<template>
  <ion-item>
    <ion-label position="stacked" class="user-select-none">Tipo de identificación</ion-label>
    <ion-select
      ref="sit"
      interface="popover"
      placeholder="Seleccione"
      :value="value"
      :interface-options="{
        cssClass: 'popover-large-options',
      }"
      @ionChange="changed($event.target.value)"
    >
      <ion-select-option
        v-for="type in identificationTypes"
        :key="type.value"
        :value="type.value"
        :disabled="type.value == '' ? true : false"
        :class="[{'ion-hide': type.value == ''}]"
      >
        {{ type.name}}
      </ion-select-option>
    </ion-select>
  </ion-item>
</template>
<script>
import {IonItem, IonLabel, IonSelect, IonSelectOption} from '@ionic/vue';
import { defineComponent } from "vue";
const SelectIdentificationType = defineComponent({
  name: 'SelectIdentificationType',
  components: {
    IonItem,
    IonLabel,
    IonSelect,
    IonSelectOption,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      identificationType: {
        name: 'Seleccione',
        value: '',
        regExp: '',
        mensaje: 'Selecciona un tipo de identificación',
      },
      identificationTypes: [
        {
          name: 'Cédula (Venezolana)',
          value: 'ven_cedula',
          regExp: '^[VE]-[0-9]{6,9}$',
          mensaje: 'La cédula comienza por V o E seguida de guión y números',
        },
        {
          name: 'Cédula (Uruguay)',
          value: 'ury_cedula',
          regExp: '^[0-9]{6,9}-[0-9]{1}$',
          mensaje: 'La cédula es numérica seguida de guión y un número',
        },
        {
          name: 'Cédula (Ecuador)',
          value: 'ecu_cedula',
          regExp: '^[0-9]{10}$',
          mensaje: 'La cédula es numérica de 10 dígitos',
        },
        {
          name: 'DNI (Argentino)',
          value: 'arg_dni',
          regExp: '^L?[0-9]{6,10}$',
          mensaje: 'El DNI debe ser numérico, opcionalmente puede comenzar'
          + ' por L',
        },
        {
          name: 'RUT (Chileno)',
          value: 'chl_rut',
          regExp: '^[0-9]{6,10}-[A-Za-z0-9]$',
          mensaje: 'Debe ser numérico seguido de un guión y un número o letra',
        },
        {
          name: 'SSN (Norte americano)',
          value: 'usa_ssn',
          regExp: '^[0-9]{3}-[0-9]{2}-[0-9]{3}$',
          mensaje: 'Debe estar compuesto por números separados por guión',
        },
        {
          name: 'Pasaporte',
          value: 'pasaporte',
          regExp: '^[A-Za-z0-9]{6,10}$',
          mensaje: 'El pasaporte debe ser alfanumérico de 6 a 10 caracteres',
        },
      ],
    };
  },
  mounted() {
    this.changed(this.value);
  },
  methods: {
    focusInput() {
      this.$refs.sit.focus();
    },
    changed(value) {
      this.$emit('input', value);
      if (value != '') {
        this.identificationType = this.identificationTypes.filter((i) =>
        i.value == value)[0]
      }
      this.$emit('save', JSON.parse(JSON.stringify(this.identificationType)));
    },
  },
});

const identificationTypes = {};
// const identificationTypes = defineComponent({});
SelectIdentificationType.data().identificationTypes.forEach((e) => {
  identificationTypes[e.value] = e.name;
});
export default SelectIdentificationType;
export {identificationTypes};
</script>
<style lang="scss" scoped>
</style>
